table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  
  table th {
    position: sticky;
    top: 2.5em;
    text-align: left;
    padding-top: 2em;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  table td {
    text-align: center;
    font-size: 2vw;
    padding: 0.75rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .trow:nth-child(even) {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
  
  .trow:nth-child(odd) {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: rgba(200, 200, 200, 0.2) !important;
  }
  
  .trow:hover {
    background-color: #39A2DB !important;
  }