.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo {
  margin-top: -75px;
  margin-bottom: -20px; 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Tab Navigation */

.Tabs {
  width: 100%;
  padding-top: 2rem;
}

ul.nav {
  width: 100%;
  font-size: 1.25rem;
  padding-bottom: 2rem;
  font-family: Gordis, sans-serif !important;
  font-weight: 500;
  margin: 1rem auto 1rem;
  display: flex;
  align-items: center;
  padding-left: 0px !important;
  justify-content: space-around;
  /* justify-content: space-between; */
  /* border: 1px solid #39A2DB; */
  /* border-radius: 2rem; */
  padding-left: 0px;
  @media (max-width: 768px) {
    width: 90%;
  }
}
 
ul.nav li {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}
 
/* ul.nav li:first-child {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}
 
ul.nav li:last-child {
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
} */
 
ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}
 
ul.nav li.active {
  background: #39A2DB;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;

}
 
/* Tab Content Styles
.TabContent {
  font-size: 2rem;
  text-align: center;
} */
